<!--
Stylized container for creating Quick Action buttons that are rendered
in the StyleColorQuickActions component. This component has a default
slot for customizing the button's icon. Any highlighting or icon
switching logic should be handled by components that derive from this.
-->
<template>
  <PlainButton
    class="action-button"
    :class="size"
    @click.stop.prevent="onClick">
    <slot/>
  </PlainButton>
</template>

<script setup>
import PlainButton from '@/components/global/sequin/PlainButton.vue' // TODO: ENG-3647 - Isolate shared code.
import { useStore } from 'vuex'

const props = defineProps({
  styleColor: {
    type: Object,
    required: true
  },
  /** @type {PropType<StyleColorSource>} */
  styleColorSource: {
    type: Object,
    required: true
  },
  styleColorSourceIndex: {
    type: Number,
    default: () => null
  },
  size: {
    type: String,
    default: 'medium',
    validator: value => ['x-large', 'large', 'medium', 'small', 'tiny'].includes(value)
  }
})
const emit = defineEmits(['click'])

const store = useStore()

async function onClick (event) {
  if (store.hasModule('closet')) {
    await store.dispatch('closet/setStyleColorSource', {
      styleColorId: props.styleColor.id,
      source: props.styleColorSource,
      sourceIndex: props.styleColorSourceIndex
    })
  }
  emit('click', event)
}
</script>

<style lang="scss" scoped>
.action-button {
  background-color: $white;
  border-radius: 50%;
  border: 1px solid $ash;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $orchid;
  padding: 0px;

  :slotted(svg) {
    width: var(--icon-size);
    height: var(--icon-size);
    overflow: visible; // Prevent icon clipping.
  }
}
.action-button.small {
  --icon-size: 13px;
}
.action-button.medium {
  --icon-size: 18px;
}
.action-button.large {
  --icon-size: 18px;
}
.action-button.x-large {
  --icon-size: 18px;
}
</style>
