/* eslint-disable */
const VueSegment = {
  install (app, voptions) {
    var i = 'analytics';
    if (window[i] !== undefined) {
      window[i].ready(() => {
        app.config.globalProperties.$segment = window[i]
      })
    } else {
      // Create a queue, but don't obliterate an existing one!
      var analytics = window[i] = window[i] || [];

      // If the real analytics.js is already on the page return.
      if (analytics.initialize) return;

      // If the snippet was invoked already show an error.
      if (analytics.invoked) {
        if (window.console && console.error) {
          console.error('Segment snippet included twice.');
        }
        return;
      }

      // Invoked flag, to make sure the snippet
      // is never invoked twice.
      analytics.invoked = true;

      // A list of the methods in Analytics.js to stub.
      analytics.methods = [
        'trackSubmit',
        'trackClick',
        'trackLink',
        'trackForm',
        'pageview',
        'identify',
        'reset',
        'group',
        'track',
        'ready',
        'alias',
        'debug',
        'page',
        'once',
        'off',
        'on',
        'addSourceMiddleware',
        'addIntegrationMiddleware',
        'setAnonymousId',
        'addDestinationMiddleware',
        'register'
      ];

      // Define a factory to create stubs. These are placeholders
      // for methods in Analytics.js so that you never have to wait
      // for it to load to actually record data. The `method` is
      // stored as the first argument, so we can replay the data.
      analytics.factory = function(method){
        return function(){
          if (window[i].initialized) return window[i][e].apply(window[i],arguments);
          
          var args = Array.prototype.slice.call(arguments);
          if (['track','screen','alias','group','page','identify'].indexOf(method)>-1) {
            var c=document.querySelector("link[rel='canonical']");
            args.push({
              __t:'bpc',
              c:c&&c.getAttribute('href')||void 0,
              p:window.location.pathname,
              u:window.location.href,
              s:window.location.search,
              t:document.title,
              r:document.referrer
            })
          }
          
          args.unshift(method);
          analytics.push(args);
          return analytics;
        };
      };
       
      // For each of the methods, generate a queueing stub.
      for (var n = 0; n < analytics.methods.length; n++) {
        var key = analytics.methods[n];
        analytics[key] = analytics.factory(key);
      }

      // Define a method to load Analytics.js and load it only once
      analytics.load = function(key, options){
        // Create an async script element based on your key.
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.setAttribute('data-global-segment-analytics-key', i)
        script.src = 'https://evs.segment.armoire.style/XbEh8DrYHm/' + voptions.sourceId + '.min.js';
        script.onload = function() {
            app.config.globalProperties.$segment = analytics
        }
        // Insert our script next to the first script element.
        var first = document.getElementsByTagName('script')[0];
        first.parentNode.insertBefore(script, first);
        analytics._loadOptions = options;
      };

      analytics._writeKey = voptions.id;
      analytics._cdn = 'https://evs.segment.armoire.style';
      analytics.SNIPPET_VERSION = '5.2.0';

      // Configuration options for segment, such as batching.
      const segmentOptions = {
        integrations: {
          "Segment.io": {
            deliveryStrategy: {
              strategy: 'batching',
              config: {
                size: 10,
                timeout: 5000
              }
            },
            apiHost: 'api.segment.armoire.style/v1',
          }
        },
        obfuscate: true
      }

      // Load Analytics.js with your key, which will automatically
      // load the tools you've enabled for your account. Boosh!
      analytics.load(voptions.id, segmentOptions);

      // Make the first page call to load the integrations. If
      // you'd like to manually name or tag the page, edit or
      // move this call however you'd like.
      analytics.page();
    }
  }
}

export default VueSegment
