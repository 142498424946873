<!--
Page header component with 2 slots:
  - topRight: Content to be shown in the top right corner. Only available on desktop screen sizes.
  - description: Content shown between the title and description text.
-->
<template>
  <div class="page-header d-flex flex-column flex-md-row position-relative">
    <div
      v-if="hasImageUrl"
      class="page-header-image"
      :style="{ backgroundImage: `url('${imageUrl}')` }"/>
    <div class="page-header-info d-flex flex-column p-md p-lg-xl justify-content-md-between">
      <div
        v-if="!isTabletOrSmaller"
        class="align-self-end">
        <slot name="topRight"/>
      </div>
      <div class="d-flex flex-column row-gap-sm row-gap-md-md">
        <h3 class="mb-0">
          {{ title }}
        </h3>
        <slot name="description"/>
        <TextCharacterLimit
          v-if="hasDescription"
          :text="description"
          :character-limit="170"
          class="page-header-description"/>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import useScreenSize from '@/composables/screenSize.js'
import TextCharacterLimit from '@/components/global/TextCharacterLimit.vue'

const props = defineProps({
  title: {
    type: String,
    required: true
  },
  description: {
    type: String,
    default: null
  },
  imageUrl: {
    type: String,
    default: null
  }
})

const { isTabletOrSmaller } = useScreenSize()

const hasImageUrl = computed(() => props.imageUrl?.length > 0)
const hasDescription = computed(() => props.description?.length > 0)
</script>

<style lang="scss" scoped>
.page-header {
  background-color: $armor;
  color: $white;

  @include media-tablet-or-larger {
    min-height: 150px;
  }

  &:not(:has(> .page-header-image)) {
    .page-header-info {
      @include media-tablet-or-larger {
        flex: 1;
      }
    }
  }

  &:has(> .page-header-image) {
    .page-header-image {
      min-height: 175px;
      background-size: cover;
      background-position: center top;

      @include media-tablet-or-larger {
        height: 300px;
        flex: 50%;
      }
    }

    .page-header-info {
      @include media-tablet-or-larger {
        flex: 50%;
      }
    }
  }
}
</style>
