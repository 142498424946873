import { breakpointsBootstrapV5, useBreakpoints } from '@vueuse/core'
import { computed, getCurrentInstance, inject } from 'vue'
import { useStore } from 'vuex'

export default function useScreenSize () {
  const breakpoints = useBreakpoints(breakpointsBootstrapV5)

  const activeBreakpoint = breakpoints.active()

  // Bootstrap class infixes.
  const sm = breakpoints.greaterOrEqual('sm')
  const md = breakpoints.greaterOrEqual('md')
  const lg = breakpoints.greaterOrEqual('lg')
  const xl = breakpoints.greaterOrEqual('xl')
  const xxl = breakpoints.greaterOrEqual('xxl')

  // Named breakpoints that we use in our design system.
  const isMobile = breakpoints.smaller('md')
  const isTabletOrLarger = breakpoints.greaterOrEqual('md')
  const isTablet = breakpoints.between('md', 'lg')
  const isTabletOrSmaller = breakpoints.smaller('lg')
  const isDesktop = breakpoints.greaterOrEqual('lg')

  // Utility function to get the value based on screen size
  function screenSizeValue ({ mobile, tablet, desktop }) {
    return computed(() =>
      isDesktop.value
        ? desktop
        : isTablet.value
          ? tablet
          : mobile
    )
  }

  return {
    activeBreakpoint,
    sm,
    md,
    lg,
    xl,
    xxl,
    isMobile,
    isTabletOrLarger,
    isTablet,
    isTabletOrSmaller,
    isDesktop,
    screenSizeValue
  }
}

// This composable is meant to provide composition API components the same
// functionality our options API components get from the global screenSizes mixin.
// Once all of our components are responsive, neither of them should be necessary.
export function useScreenSizeDeprecated () {
  const store = useStore()

  // Use getCurrentInstance() instead of pulling from the global store
  // to consume the `isMobile` computed property from global screenSizes mixin.
  const isMobile = computed(() => getCurrentInstance().proxy.isMobile)
  const forceMobile = inject('forceMobile', false)
  const isWindowMobileWidth = computed(() => store.getters['global/isMobile'])
  const viewAspectRatio = computed(() => store.getters['global/viewAspectRatio'])
  const viewHeightUnit = computed(() => store.getters['global/viewHeightUnit'])

  return {
    isMobile,
    forceMobile,
    isWindowMobileWidth,
    viewAspectRatio,
    viewHeightUnit
  }
}
