<template>
  <base-tooltip
    v-bind="$attrs"
    :max-width="maxWidth">
    <template #trigger>
      <svg-circle-icon
        icon-name="question-mark"
        :class="iconClass"
        variant="neutral"
        :height="18"
        :width="18"/>
    </template>
    <template #content>
      <slot/>
    </template>
  </base-tooltip>
</template>

<script>
export default {
  props: {
    iconClass: {
      type: String,
      default: 'ms-2'
    },
    maxWidth: {
      type: String,
      default: '300'
    }
  }
}
</script>
