import { computed, toValue } from 'vue'
import { useStore } from 'vuex'

export default function useStyleColorSet (styleColorSet) {
  const id = computed(() => toValue(styleColorSet).id)
  const hasBannerImage = computed(() => {
    const styleColorSetValue = toValue(styleColorSet)
    return Object.hasOwn(styleColorSetValue, 'bannerImageUrl') &&
      styleColorSetValue.bannerImageUrl !== ''
  })

  const bannerImageUrl = computed(() => toValue(styleColorSet).bannerImageUrl)
  const description = computed(() => toValue(styleColorSet).description)
  const hasDescription = computed(() => description.value?.length > 0)
  const location = computed(() => toValue(styleColorSet).location)
  const source = computed(() => toValue(styleColorSet).source)
  const sourceType = computed(() => {
    const lowerCaseSource = source.value.toLowerCase()
    if (lowerCaseSource.startsWith('browse')) return 'browse'
    if (lowerCaseSource.startsWith('search')) return 'search'
    if (lowerCaseSource.startsWith('collection-')) return 'collection'
    if (lowerCaseSource.startsWith('brand-')) return 'brand'
    if (lowerCaseSource.startsWith('collections')) return 'marketing'
    if (lowerCaseSource.startsWith('stylist')) return 'stylist'
    return 'closet'
  })

  const mobileHeaderTitle = computed(() => sourceType.value === 'search' ? 'Search' : 'Collections')
  const styleColors = computed(() => toValue(styleColorSet).styleColors)
  const name = computed(() => toValue(styleColorSet).name)
  const length = computed(() => toValue(styleColorSet).length)
  const isFavoritesOrBtb = computed(() => ['favorites', 'be_the_buyer'].includes(source.value))
  const isClientCollection = computed(() => {
    const store = useStore()
    if (isFavoritesOrBtb.value) return true
    if (!toValue(styleColorSet).client) return false
    if (toValue(styleColorSet).client.id === store.state.client.id) return true
    return false
  })
  const isOtherClientCollection = computed(() => {
    if (toValue(isClientCollection)) return false
    return Object.hasOwn(toValue(styleColorSet), 'client')
  })
  const isArmoireCollection = computed(() => {
    return !toValue(isClientCollection) && !toValue(isOtherClientCollection) && !isFavoritesOrBtb.value
  })
  const collectionOwner = computed(() => {
    if (toValue(isClientCollection)) {
      return { name: 'you' }
    }
    if (toValue(isArmoireCollection)) {
      return { name: 'Armoire' }
    }
    if (toValue(isOtherClientCollection)) {
      return { name: toValue(styleColorSet).client.firstName, profilePic: toValue(styleColorSet).client.profilePic }
    } else return null
  })
  const isOwnCollection = computed(() => collectionOwner.value?.name === 'you')
  const hideSort = computed(() => {
    return Object.hasOwn(toValue(styleColorSet), 'hideSort') && toValue(styleColorSet).hideSort
  })
  const subtitle = computed(() => {
    if (isClientCollection.value) {
      return 'You created this collection'
    }
    return 'Created by:'
  })
  return {
    id,
    bannerImageUrl,
    hasBannerImage,
    hasDescription,
    location,
    description,
    sourceType,
    mobileHeaderTitle,
    styleColors,
    name,
    length,
    isClientCollection,
    isOtherClientCollection,
    collectionOwner,
    isOwnCollection,
    hideSort,
    isFavoritesOrBtb,
    subtitle
  }
}
