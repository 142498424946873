export function track (...args) {
  if (!window.App || !window.App.$store || window.App.$store?.state?.client.reportEvents) {
    window.analytics.track(...args)
  }
}

export function identify (client, callback = () => {}) {
  if (typeof window.analytics !== 'undefined' && client.isAuthenticated) {
    // eslint-disable-next-line
    if (client.reportEvents) {
      let shippingAddress = ''
      if (client.shippingAddress) {
        const a = client.shippingAddress
        const addressLine2 = a.addressLine2 ? ` ${a.addressLine2}` : ''
        shippingAddress = `${a.addressLine1}${addressLine2}, ${a.city}, ${a.state} ${a.zipcode}`
      }
      const plan = client.plans?.currentBasePlan ? client.plans.currentBasePlan.name : ''
      // NOTE:  Keep this in sync with the backend identify call properties as much as possible
      const properties = {
        babyDate: client.babyDate,
        babyDate_at: client.babyDate,
        birthDate: client.birthDate,
        birthDate_at: client.birthDate,
        caseSize: client.caseSize,
        email: client.email,
        firstName: client.firstName,
        influencer: client.influencer,
        isStaff: client.isStaff,
        lastName: client.lastName,
        leadStage: client.leadStage,
        leadSource: client.leadSource,
        leadSourceDetail: client.leadSourceDetail,
        member: client.membershipStatus === 'active',
        paidThrough: client.paidThrough,
        paidThrough_at: client.paidThrough,
        phone: client.mainPhone.replace(/-/g, ''),
        plan: plan,
        shippingAddress: shippingAddress,
        subscriptionStatus: client.membershipStatus,
        zipCode: client.zipCode
      }
      window.analytics.identify(
        client.username,
        properties,
        {
          Intercom: {
            user_hash: client.clientHash
          }
        },
        callback
      )
    } else {
      callback()
    }
  }
}
