<template>
  <div class="d-flex align-items-center">
    <div
      v-if="!isTabletOrSmaller"
      class="collection-owner-header">
      <div class="d-flex align-items-center">
        <p class="header-subtitle m-0 fine-print text-white">
          {{ subtitle }}
        </p>
        <div
          v-if="!isOwnCollection"
          class="collection-owner-icon">
          <SvgCircleIcon
            v-if="isArmoireCollection"
            class="svg-circle-icon"
            icon-name="origami-dress-fixed"
            fill="white"
            :height="32"
            :width="32"
            :padding="14"/>
          <AvatarImage
            v-else
            :profile-pic="owner.profilePic"
            size="small"
            class="avatar-image p-1"/>
          <h5 class="m-0 text-white">
            {{ owner.name }}
          </h5>
        </div>
      </div>
    </div>
    <PopoverDropdown
      v-if="isOwnCollection"
      ref="popoverDropdown"
      variant="right"
      class="collection-owner-share-button">
      <template #button>
        <SvgCircleIcon
          :icon-name="isOwnCollection ? 'more' : 'share'"
          :height="32"
          :width="32"
          :stroke-width="2"
          :circle-stroke="false"
          inverse/>
      </template>
      <template #dropdown-content>
        <div class="options-list">
          <TextLink
            v-for="(option, index) in dropdownOptions"
            :key="index"
            class="option d-block text-center w-100 border-bottom text-button p-2"
            :underline="false"
            @click="option.onClick">
            {{ option.text }}
          </TextLink>
        </div>
      </template>
    </PopoverDropdown>
    <div v-else>
      <PlainButton
        class="m-0 p-0 collection-owner-share-button"
        @click="emit('on-share-style-color-set-page') ">
        <SvgCircleIcon
          icon-name="share"
          :height="32"
          :width="32"
          :stroke-width="2"
          :circle-stroke="false"
          inverse/>
      </PlainButton>
    </div>
  </div>
</template>
<script setup>
import { computed, ref } from 'vue'
import useScreenSize from '@/composables/screenSize.js'
import AvatarImage from '@/components/community/common/AvatarImage.vue'
import PopoverDropdown from '@/components/global/sequin/PopoverDropdown.vue'
import TextLink from '@/components/global/sequin/TextLink.vue'
import SvgCircleIcon from '@/components/global/svg/SvgCircleIcon.vue'
import PlainButton from './sequin/PlainButton.vue'

const { isTabletOrSmaller } = useScreenSize()
const props = defineProps({
  owner: {
    type: Object,
    required: true
  },
  subtitle: {
    type: String,
    required: true
  }
})

const popoverDropdown = ref(null)
const emit = defineEmits({
  'on-edit-collection': (event) => event instanceof MouseEvent,
  'on-delete-collection': (event) => event instanceof MouseEvent,
  'on-share-style-color-set-page': (event) => event instanceof MouseEvent
})
const isArmoireCollection = computed(() => props.owner.name === 'Armoire')
const isOwnCollection = computed(() => props.owner.name === 'you')
// dropdown options adds edit and delete if isOwnCollection
const dropdownOptions = computed(() => {
  const options = [
    {
      text: 'Share Collection',
      onClick: () => { emit('on-share-style-color-set-page') }
    }, {
      text: 'Edit Collection',
      onClick: () => { emit('on-edit-collection') }
    },
    {
      text: 'Delete Collection',
      onClick: () => { emit('on-delete-collection') }
    }
  ]
  return options
})
</script>
<style lang="scss" scoped>
:deep(.dropdown-menu) {
  padding: 0;
  width: unset;
  margin-top: $spacing-xs;
  border-radius: $sequin-border-radius;
  @include media-tablet-or-smaller  {
    // 102vw to account for dropdown
  width: 102vw;
  }
}
:deep(.dropdown-menu.right) {
  @include media-tablet-or-smaller  {
  right: -18px;
  }
}
.collection-owner-icon {
  margin: 0 $spacing-xs;
  display: flex;
  align-items: center;
  gap: $spacing-xs;
}
.collection-owner-share-button {
  margin-left: $spacing-xs;
}
</style>
