<template>
  <slot
    v-if="showVariant"
    v-bind="$attrs"
    :name="`variant${toPascalCase(variant)}`">
    <span v-if="showPlaceholders">{{ `Variant for ${name}: ${variant}` }}</span>
  </slot>
  <slot
    v-else-if="showOriginal"
    v-bind="$attrs">
    <span v-if="showPlaceholders">{{ `Original for ${name}. ${isActive ? `Available variants: ${variantNames.join(', ')}` : `Experiment is inactive.`}` }}</span>
  </slot>
</template>

<script setup>
import useExperiment from '@shared/composables/experiment.js'
import { toPascalCase } from '@/utils/stringParsing.js' // TODO: ENG-3647 - Isolate shared code.

const props = defineProps({
  name: {
    type: String,
    required: true
  }
})

const showPlaceholders = process.env.NODE_ENV !== 'production'
const { isActive, variant, showVariant, showOriginal, variantNames } = useExperiment(props.name)
</script>
