<!-- Collect Quick Action Button -->
<template>
  <StyleColorQuickActionButton
    :style-color="styleColor"
    :style-color-source="styleColorSource"
    :style-color-source-index="styleColorSourceIndex"
    :size="size"
    @click="onClick">
    <SvgHeart :class="{ 'highlight': isCollected }"/>
  </StyleColorQuickActionButton>
</template>

<script setup>
import { useModals } from '@/composables/modals.js'
import StyleColorQuickActionButton from './StyleColorQuickActionButton.vue'
import SvgHeart from '@/components/global/svg/SvgHeart.vue'
import { useToastNotifications } from '@/composables/toastNotifications.js'
import { computed } from 'vue'
import { useStore } from 'vuex'

const props = defineProps({
  styleColor: {
    type: Object,
    required: true
  },
  /** @type {PropType<StyleColorSource>} */
  styleColorSource: {
    type: Object,
    required: true
  },
  styleColorSourceIndex: {
    type: Number,
    default: () => null
  },
  size: {
    type: String,
    default: 'medium',
    validator: value => ['x-large', 'large', 'medium', 'small', 'tiny'].includes(value)
  }
})
const emit = defineEmits(['click'])

const store = useStore()
const modals = useModals()
const toastNotifications = useToastNotifications()

const isAuthenticated = computed(() => store.state.client?.isAuthenticated ?? false)
const ratingsMap = computed(() => store.state.closet?.ratingsMap ?? {})
const collectedStyleColors = computed(() => store.state.collections?.collectedStyleColors ?? [])
const isCollected = computed(() => ratingsMap.value[props.styleColor.id] === 1 ||
  collectedStyleColors.value.indexOf(props.styleColor.id) !== -1)
const hasFavorites = computed(() => Object.values(ratingsMap.value).some(value => value === 1))

async function onClick (event) {
  if (!isAuthenticated.value || !hasFavorites.value) {
    modals.openClosetRatingInfoModal('favorite')
  }
  if (isAuthenticated.value) {
    const styleColorId = props.styleColor.id
    if (!isCollected.value) {
      // Show toast notification before dispatching so it shows up immediately.
      toastNotifications.showAddedToFavoritesToast(styleColorId)
      await store.dispatch('closet/favorite', { styleColorId })
    } else {
      modals.openAddToCollectionModal(styleColorId)
    }
  }
  emit('click', event)
}

defineExpose({
  isCollected
})
</script>

<style lang="scss" scoped>
.highlight {
  fill: $orchid;
}
</style>
