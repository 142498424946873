// Pinia store for open overlays (modals and flyouts).
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import zIndexes from '@/assets/styles/zIndexes.module.scss'

export const useOverlaysStore = defineStore('overlays', () => {
  const modals = ref({
    // name: {
    //   name: '',
    //   context: {},
    //   zIndex: 0
    // }
  })
  const flyouts = ref({
    // name: {
    //   name: '',
    //   context: {},
    //   zIndex: 0
    // }
  })

  const modalName = computed(() => latestModal.value?.name ?? null)
  const modalContext = computed(() => latestModal.value?.context ?? null)
  const modalZIndex = computed(() => latestModal.value?.zIndex ?? null)
  const flyoutName = computed(() => latestFlyout.value?.name ?? null)
  const flyoutContext = computed(() => latestFlyout.value?.context ?? null)
  const flyoutZIndex = computed(() => latestFlyout.value?.zIndex ?? null)
  const isAnyModalOpen = computed(() => Object.keys(modals.value).length > 0)
  const isAnyFlyoutOpen = computed(() => Object.keys(flyouts.value).length > 0)
  const isAnyOverlayOpen = computed(() => isAnyModalOpen.value || isAnyFlyoutOpen.value)
  const latestModal = computed(() => {
    // Return the modal with the highest z-index
    return Object.keys(modals.value).reduce((acc, key) => {
      if (!acc) return modals.value[key]
      if (modals.value[key].zIndex > acc.zIndex) return modals.value[key]
      return acc
    }, null)
  })
  const latestFlyout = computed(() => {
    // Return the flyout with the highest z-index
    return Object.keys(flyouts.value).reduce((acc, key) => {
      if (!acc) return flyouts.value[key]
      if (flyouts.value[key].zIndex > acc.zIndex) return flyouts.value[key]
      return acc
    }, null)
  })
  const latest = computed(() => {
    if (!latestModal.value && !latestFlyout.value) return null
    if (!latestModal.value) return { type: 'flyout', ...latestFlyout.value }
    if (!latestFlyout.value) return { type: 'modal', ...latestModal.value }
    return latestModal.value.zIndex > latestFlyout.value.zIndex
      ? { type: 'modal', ...latestModal.value }
      : { type: 'flyout', ...latestFlyout.value }
  })
  const baseModalZIndex = computed(() => parseInt(zIndexes.modal))
  const baseFlyoutZIndex = computed(() => parseInt(zIndexes['above-intercom']))
  const latestZIndex = computed(() => latest.value?.zIndex ?? null)
  const nextModalZIndex = computed(() => latestZIndex.value >= baseModalZIndex.value ? latestZIndex.value + 1 : baseModalZIndex.value)
  const nextFlyoutZIndex = computed(() => latestZIndex.value >= baseFlyoutZIndex.value ? latestZIndex.value + 1 : baseFlyoutZIndex.value)

  function isModalOpen (name) {
    return !!modals.value[name]
  }
  function isFlyoutOpen (name) {
    return !!flyouts.value[name]
  }
  function openModal ({ name, context }) {
    // Currently, we only track one open modal at a time.
    if (isAnyModalOpen.value) {
      closeModal()
    }
    modals.value[name] = {
      name,
      context,
      zIndex: nextModalZIndex.value
    }
  }
  function closeModal (name = null) {
    if (name && typeof name === 'string') {
      delete modals.value[name]
    } else {
      if (latestModal.value) {
        delete modals.value[latestModal.value.name]
      }
    }
  }
  function openFlyout ({ name, context, reuse = false }) {
    // If the flyout is already open as the latest flyout, just update the context.
    if (reuse && flyoutName.value === name) {
      flyouts.value[name].context = context
      return
    }
    // Currently, we only track one open flyout at a time.
    if (isAnyFlyoutOpen.value) {
      closeFlyout()
    }
    flyouts.value[name] = {
      name,
      context,
      zIndex: nextFlyoutZIndex.value
    }
  }
  function closeFlyout (name = null) {
    if (name && typeof name === 'string') {
      delete flyouts.value[name]
    } else {
      if (latestFlyout.value) {
        delete flyouts.value[latestFlyout.value.name]
      }
    }
  }
  function closeLatest () {
    if (!latest.value) return false
    if (latest.value.type === 'modal') {
      closeModal(latest.value.name)
    } else {
      closeFlyout(latest.value.name)
    }
    return true
  }
  function closeAllModals () {
    modals.value = {}
  }
  function closeAllFlyouts () {
    flyouts.value = {}
  }
  function closeAll () {
    closeAllModals()
    closeAllFlyouts()
  }

  return {
    modals,
    flyouts,
    modalName,
    modalContext,
    modalZIndex,
    flyoutName,
    flyoutContext,
    flyoutZIndex,
    isAnyModalOpen,
    isAnyFlyoutOpen,
    isAnyOverlayOpen,
    latestModal,
    latestFlyout,
    latest,
    baseModalZIndex,
    baseFlyoutZIndex,
    latestZIndex,
    nextModalZIndex,
    nextFlyoutZIndex,
    isModalOpen,
    isFlyoutOpen,
    openModal,
    closeModal,
    openFlyout,
    closeFlyout,
    closeLatest,
    closeAllModals,
    closeAllFlyouts,
    closeAll
  }
})
