<template>
  <div
    class="circle-bg d-inline-flex align-items-center justify-content-center"
    :class="[variant, { inverse, circleStroke }]"
    :style="{'width': width, 'height': height}">
    <component
      :is="`svg-${iconName}`"
      v-if="iconName"
      :width="width - padding"
      :height="height - padding"
      :stroke-width="strokeWidth"
      :fill="fill"/>
  </div>
</template>

<script>

/**
 * Use this component to put any svg icon on a circular background
 */
export default {
  props: {
    iconName: {
      type: String,
      default: 'check'
    },
    height: {
      type: Number,
      default: 24
    },
    width: {
      type: Number,
      default: 24
    },
    strokeWidth: {
      type: Number,
      default: 3
    },
    fill: {
      type: String,
      default: 'none'
    },
    padding: {
      type: Number,
      default: 8
    },
    variant: {
      type: String,
      default: 'primary',
      validator: value => {
        return value.match(/(primary|secondary|tertiary|champagne|success|info|danger|warning|dark|neutral|plum)/)
      }
    },
    inverse: {
      type: Boolean,
      default: false
    },
    circleStroke: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>

  .circle-bg {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;

    &.primary {
      background-color: $primary;
    }

    &.secondary {
      background-color: $secondary;
    }

    &.tertiary {
      background-color: $tertiary;
    }

    &.champagne {
      background-color: $champagne;
      color: $plum;
    }

    &.success {
      background-color: $success-light;
    }

    &.info {
      background-color: $info-dark;
    }

    &.danger {
      background-color: $danger-dark;
    }

    &.warning {
      background-color: $warning-light;
    }

    &.neutral {
      background-color: $origami;
      color: $pewter;
    }

    &.dark {
      background-color: $pewter;
    }
    &.plum {
      background-color: $plum;
    }
    &.inverse {
      background-color: $white;
      color: $plum;
      &.primary { color: $primary; }
      &.secondary { color: $secondary; }
      &.tertiary { color: $tertiary; }
      &.success { color: $success-light; }
      &.info { color: $info-dark; }
      &.danger { color: $danger-dark; }
      &.warning { color: $warning-light; }
      &.neutral .dark { color: $pewter; }
    }

    &.circleStroke {
      border: 1px solid $ash;
    }
}

</style>
